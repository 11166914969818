.team-template {
    text-align: center;
    padding: 1%;
  }
  
  .team-title {
    font-size: 2.5em;
    color: #fff;
    margin-bottom: 10px;
   
  }
  