body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.footer-section {
  display: flex;
  justify-content: space-between;
  align-items: flex-start; /* Align items at the top */
  background-color: #000;
  padding: 20px;
  color: white;
  flex-wrap: wrap; /* Ensures responsive layout */
}

.footer-left {
  flex: 1;
}

.footer-right {
  display: flex;
  flex-direction: column;
  align-items: flex-end; /* Aligns button and contact to the right */
}

.cta-contact {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: 10px;
}

.cta-button {
  background-color: white;
  color: black;
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
  margin-bottom: 15px; /* Space between the button and contact info */
}

.cta-button:hover {
  background-color: #ccc;
}

.contact-link {
  display: block;
  color: white;
  text-decoration: none;
  margin-bottom: 5px;
}

.footer-bottom {
  text-align: center;
  margin-top: 20px;
  flex-basis: 100%; /* Ensures the footer text takes full width on smaller screens */
}

.social-media-icons {
  display: flex;
  gap: 10px; /* Space between each social media icon */
  margin-top: 10px; /* Space between address and social media icons */
}

.social-media-icon i {
  font-size: 24px; /* Size of the icons */
  color: white;
  transition: color 0.3s ease;
}

.social-media-icon i:hover {
  color: #ccc; /* Color change on hover */
}
