.team-member-card {
  display: flex;
  align-items: flex-start; /* Aligns the text to the top of the image */
  background-color: #222;
  padding: 20px;
  border-radius: 10px;
  margin: 20px 0;
  position: relative;
}

.team-member-img {
  border-radius: 10px;
  width: 250px;
  height: 400px;
  object-fit: cover;
  flex-shrink: 0;
}

.team-member-info {
  margin-left: 20px; /* Ensures consistent spacing between image and text */
  color: #fff;
  max-width: calc(100% - 240px); /* Adjusts width based on image size */
  text-align: left; /* Ensures the text is left-aligned */
}

.team-member-name {
  font-size: 2em;
  font-weight: bold;
  margin-bottom: 10px; /* Adds space between name and title */
}

.team-member-title {
  font-size: 1.2em;
  color: #ADD8E6; /* Custom color matching the design */
  margin-bottom: 20px; /* Adds space between title and description */
}

.team-member-description {
  font-size: 1em;
  line-height: 1.5;
  margin-bottom: 0; /* Ensures the text aligns flush with the bottom of the image */
}

@media (max-width: 768px) {
  .team-member-card {
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
  }

  .team-member-img {
    width: 100%;
    height: auto;
    border-radius: 10px;
    margin-bottom: 20px; /* Adds space between the image and the text */
  }

  .team-member-info {
    margin-left: 0;
    max-width: 100%;
    text-align: left; /* Ensure the text is left-aligned */
    background: none; /* Remove background */
    padding: 0;
    position: relative; /* Remove absolute positioning */
  }

  .team-member-name {
    font-size: 1.5em;
  }

  .team-member-title {
    font-size: 1.2em;
  }

  .team-member-description {
    font-size: 0.9em;
    margin-top: 5px;
  }
}

@media (max-width: 768px) {
  .team-member-card {
    flex-direction: column; /* Stack image and text vertically */
    align-items: center; /* Center-align items */
    padding: 1px;
    color: #fff;
  }

  .team-member-img {
    width: 100%; /* Full width on mobile */
    height: auto; /* Maintain aspect ratio */
    margin-bottom: 20px; /* Add space between image and text */
  }

  .team-member-info {
    margin-left: 0; /* Remove left margin */
    max-width: 100%; /* Use full width for text */
    text-align: center; /* Center-align text on mobile */
  }

  .team-member-name {
    font-size: 1.8em; /* Slightly smaller font size on mobile */
  }

  .team-member-title {
    font-size: 1.1em;
  }

  .team-member-description {
    font-size: 1em;
    margin-top: 10px; /* Add space between description and title */
  }
}

