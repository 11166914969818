/* Style for the scrollbar */
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #040707; /* Color of the scrollbar track */
}

::-webkit-scrollbar-thumb {
  background: linear-gradient(
    #040707,
    #555555
  ); /* Color of the scrollbar thumb */
  border-radius: 6px;
}

body {
  overflow-y: scroll; /* Always show vertical scrollbar */
}
